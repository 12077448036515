// eslint-disable-next-line
import { lazy } from "react";

import {
    // CreateDatabase,
    CreateDatalake,
    CreateMenu,
    CreatePermission,
    CreateRole,
    CreateUser,
    Datalake,
    Datamart,
    EditConfig,
    EditMenu,
    EditPermission,
    EditRole,
    EditUser,
    ListMenu,
    ListPermission,
    ListRole,
    ListUser,
    SortMenu,
} from "pages";
import Login from "pages/auth/login";
import CctvList from "pages/cctv/cctv-list/CctvList";
import DynamicDashboard from "pages/dynamicDashboard";

import PrivateLayout from "components/Layout/PrivateLayout/PrivateLayout";
import PrivateCreateMenus from "components/Layout/PrivateLayout/PrivateCreateMenus"
import PrivateEditMenus from 'components/Layout/PrivateLayout/PrivateEditMenus'
import PrivateSettingRole from 'components/Layout/PrivateLayout/PrivateSettingRole'
import PrivateSortMenus from 'components/Layout/PrivateLayout/PrivateSortMenus'
import PrivateCreateUsers from 'components/Layout/PrivateLayout/PrivateCreateUsers'
import PrivateEditUsers from 'components/Layout/PrivateLayout/PrivateEditUsers'
import PrivateSettingUsers from 'components/Layout/PrivateLayout/PrivateSettingUsers'


const Dashboard = lazy(() => import("pages/dashboard"));

const routes: Routes[] = [
    {
        path: "/",
        name: "dashboard",
        component: <Dashboard />,
    },
    //dataset
    {
        path: "/dataset",
        name: "dataset",
        component: (
            <PrivateLayout>
                <Datamart />
            </PrivateLayout>
        ),
    },
    {
        path: "/datalake",
        name: "datalake",
        component: (
            <PrivateLayout>
                <Datalake />
            </PrivateLayout>
        ),
    },

    {
        name: "create_datamart",
        path: "/setting/datalake/create",
        hide: true,
        component: (
            <PrivateLayout>
                <CreateDatalake />
            </PrivateLayout>
        ),
    },
    // {
    //     name: "create_database",
    //     path: "/setting/database/create",
    //     hide: true,
    //     component: (
    //         <PrivateLayout>
    //             <CreateDatabase />
    //         </PrivateLayout>
    //     ),
    // },
    {
        path: "/:parent_menu/:menu/:source",
        name: "dynamic_menu",
        component:
            <PrivateLayout>
                <DynamicDashboard />,
            </PrivateLayout>
    },

    {
        name: "list_user",
        path: "/setting/users",
        component: (
            <PrivateLayout>
                <PrivateSettingUsers>
                    <ListUser />
                </PrivateSettingUsers>
            </PrivateLayout>
        ),
    },
    {
        name: "create_user",
        path: "/setting/users/create",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateCreateUsers>
                    <CreateUser />
                </PrivateCreateUsers>
            </PrivateLayout>
        ),
    },
    {
        name: "edit_user",
        path: "/setting/users/edit/:id",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateEditUsers>
                    <EditUser />
                </PrivateEditUsers>
            </PrivateLayout>
        ),
    },

    // Role Management
    {
        name: "list_role",
        path: "/setting/roles",
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <ListRole />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    {
        name: "create_role",
        path: "/setting/roles/create",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <CreateRole />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    {
        name: "edit_role",
        path: "/setting/roles/edit/:id",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <EditRole />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },

    // Menu Managemen
    {
        name: "list_menu",
        path: "/setting/menus",
        component: (
            <PrivateLayout>
                <ListMenu />
            </PrivateLayout>
        ),
    },
    {
        name: "create_menu",
        path: "/setting/menus/create",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateCreateMenus>
                    <CreateMenu />
                </PrivateCreateMenus>
            </PrivateLayout>
        ),
    },
    {
        name: "edit_menu",
        path: "/setting/menus/edit/:id",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateEditMenus>
                    <EditMenu />
                </PrivateEditMenus>
            </PrivateLayout>
        ),
    },

    // Permission Managemen
    {
        name: "list_permission",
        path: "/setting/permissions",
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <ListPermission />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    {
        name: "create_permission",
        path: "/setting/permissions/create",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <CreatePermission />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    {
        name: "edit_permission",
        path: "/setting/permissions/edit/:id",
        hide: true,
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <EditPermission />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },

    {
        name: "list_cctv",
        path: "apps/cctv/:parent_menu/:menu",
        hide: true,
        component: <CctvList />,
    },

    {
        name: "edit_config",
        path: "/setting/config/edit/:id",
        component: (
            <PrivateLayout>
                <PrivateSettingRole>
                    <EditConfig />
                </PrivateSettingRole>
            </PrivateLayout>
        ),
    },
    {
        name: "login",
        path: "/login",
        hide: true,
        component: <Login />,
    },
    {
        name: "sort_menu",
        path: "/setting/menus/sort",
        component: (
            <PrivateLayout>
                <PrivateSortMenus>
                    <SortMenu />
                </PrivateSortMenus>
            </PrivateLayout>
        ),
    },
];

export default routes;
