import React from "react";
import { Navigate } from "react-router-dom";

import { useGetMeQuery } from "redux/services/apiService";
import { selectUserPermissions } from "redux/slices/user";
import { useAppSelector } from "redux/store";

type Props = {};
const PrivateEditMenus: React.FC<Props> = ({ children }): JSX.Element => {
    const selectPermission = useAppSelector(selectUserPermissions);
    const viewPermission = (permission: String) => {
        return selectPermission.some((p) => p === permission);
    };

    const { isLoading } = useGetMeQuery();
    if (isLoading) {
        return null;
    }

    if (!viewPermission("edit.menus")) {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
};

export default PrivateEditMenus;
