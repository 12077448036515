import React from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Center,
  Flex,
  Skeleton,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { useGetMeQuery } from "redux/services/apiService";

import Video from "components/Video";

import { useGetCctvListQuery } from "../../../redux/services/cctv";

type Props = {};
const CctvList: React.FC<Props> = (): JSX.Element => {
  const params = useParams();
  const { data: cctv } = useGetCctvListQuery(params?.menu);
  const { isLoading } = useGetMeQuery();
  let on = true;
  for (let i = 0; i < cctv?.data?.length; i++) {
    if (cctv?.data[0]?.connection === 1) {
      break;
    }
    on = false;
  }
  if (on) {
    return (
      <Skeleton isLoaded={!isLoading}>
        <Center>
          <Wrap justify="center">
            {cctv?.data
              ?.filter((f) => f.connection)
              .map((cctv) => (
                <WrapItem key={cctv.id}>
                  <VStack
                    align="start"
                    bg="#fff"
                    p="5"
                    borderRadius="sm"
                    mt="4"
                    mr="4"
                    spacing={4}
                  >
                    <Flex
                      justifyContent="space-between"
                      align={"center"}
                      w="full"
                    >
                      <Box>
                        <h3
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            backgroundColor: "#C0392B",
                            color: "white",
                            paddingLeft: 5,
                          }}
                        >
                          {cctv.name}
                        </h3>
                        <Video
                          options={{
                            autoplay: false,
                            controls: true,
                            width: 300,
                            height: 175,
                            sources: [
                              {
                                src: cctv.url,
                                type: "application/x-mpegURL",
                              },
                            ],
                          }}
                        />
                      </Box>
                    </Flex>
                  </VStack>
                </WrapItem>
              ))}
          </Wrap>
        </Center>
      </Skeleton>
    );
  }
  return (
    <Stack align="center">
      <Text fontSize="5xl" fontWeight="bold" textAlign="center">
        Response CCTV tidak aktif
      </Text>
    </Stack>
  );
};

export default CctvList;
