import React from "react";

import videojs from "video.js";

import "video.js/dist/video-js.css";
type Props = {
  options?: Partial<{
    audioOnlyMode: boolean;
    autoplay: "muted" | "play" | "any" | boolean;
    control: boolean;
    height: number;
    width: number;
    loop: boolean;
    muted: boolean;
    src: string;
    fill: boolean;
    controls: boolean;
    sources: { src: string; type: string }[];
    responsive: boolean;
    preferFullWindow: boolean;
    fluid: boolean;
    preload: "auto" | "metadata" | "none";
  }>;
  onReady?: (player: any) => void;
};
const Video: React.FC<Props> = (props): JSX.Element => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        player.log("player is ready");
        onReady && onReady(player);
      }));

      // You can update player in the `else` block here, for example:
    } else {
      playerRef.current.autoplay(options.autoplay);
      playerRef.current.src(options.sources);
    }
  }, [options, videoRef, onReady]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
};

export default Video;
