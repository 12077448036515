import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

import * as en from "./locales/en";
import * as id from "./locales/id";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en,
  id,
};

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    defaultNS: ["common"],
    ns: ["common"],
    resources,
    supportedLngs: ["en", "id"],
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
