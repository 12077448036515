import React from "react";

type Props = {
  source: string;
};
const GoogleDataStudio: React.FC<Props> = ({ source }): JSX.Element => (
  <iframe
    title="Google Data Studio"
    src={source}
    frameBorder="0"
    style={{
      height: "78vh",
    }}
    height="100%"
    width="100%"
  />
);

export default GoogleDataStudio;
