import { apiService } from "redux/services/apiService";

const cctvApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    getCctvList: build.query<any, string>({
      query: (cctvLoc: string) => ({
        url: `/cctv?filter[location]=${cctvLoc}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetCctvListQuery } = cctvApi;
