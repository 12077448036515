// import store from "redux/store";

import Color from "color";

const generateColorScale = (centerColor: string) => {
  // const brandColor = store.getState()?.rootReducer?.color?.color ?? "#c0392b";

  // console.log("generateColorScale", store.getState());

  const config = {
    centerColor,
    darkest: {
      lightness: 10,
      rotate: 0,
      saturate: 0,
    },
    lightest: {
      lightness: 95,
      rotate: 0,
      saturate: 0,
    },
  };

  //   const color = config.centerColor;
  const darkSteps = 4;
  const lightSteps = 5;

  const lightnessStep = (config.lightest.lightness - 50) / lightSteps;
  const darknessStep = (50 - config.darkest.lightness) / darkSteps;

  const lightRotateStep = config.lightest.rotate / lightSteps;
  const darkRotateStep = config.darkest.rotate / darkSteps;

  const lightSaturateStep = config.lightest.saturate / lightSteps;
  const darkSaturateStep = config.darkest.saturate / darkSteps;

  const scale = {
    50: Color(config.centerColor)
      .lightness(50 + lightnessStep * 5)
      .rotate(lightRotateStep * 5)
      .saturate(lightSaturateStep * 5)
      .hex(),
    100: Color(config.centerColor)
      .lightness(50 + lightnessStep * 4)
      .rotate(lightRotateStep * 4)
      .saturate(lightSaturateStep * 4)
      .hex(),
    200: Color(config.centerColor)
      .lightness(50 + lightnessStep * 3)
      .rotate(lightRotateStep * 3)
      .saturate(lightSaturateStep * 3)
      .hex(),
    300: Color(config.centerColor)
      .lightness(50 + lightnessStep * 2)
      .rotate(lightRotateStep * 2)
      .saturate(lightSaturateStep * 2)
      .hex(),
    400: Color(config.centerColor)
      .lightness(50 + lightnessStep * 1)
      .rotate(lightRotateStep * 1)
      .saturate(lightSaturateStep * 1)
      .hex(),
    500: Color(config.centerColor).lightness(50).hex(),
    600: Color(config.centerColor)
      .lightness(50 - darknessStep * 1)
      .rotate(darkRotateStep * 1)
      .saturate(darkSaturateStep * 1)
      .hex(),
    700: Color(config.centerColor)
      .lightness(50 - darknessStep * 2)
      .rotate(darkRotateStep * 2)
      .saturate(darkSaturateStep * 2)
      .hex(),
    800: Color(config.centerColor)
      .lightness(50 - darknessStep * 3)
      .rotate(darkRotateStep * 3)
      .saturate(darkSaturateStep * 3)
      .hex(),
    900: Color(config.centerColor)
      .lightness(50 - darknessStep * 4)
      .rotate(darkRotateStep * 4)
      .saturate(darkSaturateStep * 4)
      .hex(),
  };

  return scale;
};

export default generateColorScale;
