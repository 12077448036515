import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "redux/store";

interface IColor {
  color: string;
}

const initialState: IColor = {
  color: "#c0392b",
};

const colorSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setColor: (state: IColor, { payload }: PayloadAction<IColor>) => {
      state.color = payload.color;
    },
  },
});

export const selectBrandColor = (state: RootState) =>
  state.rootReducer.color.color;
export const { setColor } = colorSlice.actions;

export default colorSlice.reducer;
