import { apiService } from "redux/services/apiService";
import { ResponseDelete } from "redux/services/types";

import { Menu, PostMenu } from "./types";

const TYPE = "menu";
const menuApi = apiService.injectEndpoints({
    endpoints: (build) => ({
        getMenus: build.query<Menu[], void>({
            query: () => ({ url: "/menus" }),
            providesTags: (result) => {
                if (result) {
                    return [
                        ...result.map(({ id }) => ({ type: TYPE, id })),
                        { type: TYPE, id: "list" },
                    ] as any;
                }

                return [{ type: TYPE, id: "list" }];
            },
        }),
        getMenusFlat: build.query({
            query: ({ page, search }) => ({ url: `/menus/flat?page=${page}&search=${search}` }),
            providesTags: [TYPE],
        }),
        getMenu: build.query<Menu, string>({
            query: (menuId: string) => ({ url: `/menus/${Number(menuId)}` }),
            providesTags: (result) => [{ type: TYPE, id: result.id }],
        }),
        createMenu: build.mutation<Menu, PostMenu>({
            query: (data) => {
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    formData.append(key, data[key]);
                });
                return {
                    url: "/menus",
                    headers: {
                        "Content-type": "multipart/form-data",
                    },
                    method: "post",
                    data: formData
                }
            },
            invalidatesTags: [
                { type: TYPE, id: "list" },
                { type: TYPE, id: "list_flat" },
            ],
        }),
        createViewCount: build.mutation({
            query: (data) => ({ url: "/menus/viewCount", method: "post", data: data }),
            invalidatesTags: [
                { type: TYPE, id: "list" },
                { type: TYPE, id: "list_flat" },
            ],
        }),
        editSort: build.mutation({
            query: (data) => ({ url: `/menus/sort`, method: "post", data: data }),
            invalidatesTags: [TYPE],
        }),
        updateMenu: build.mutation<Menu, Pick<Menu, "id"> & PostMenu>({
            query: ({ id, ...data }) => {
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    formData.append(key, data[key]);
                });
                return {
                    url: `/menus/${id}`,
                    headers: {
                        "Content-type": "multipart/form-data",
                    },
                    method: "post",
                    data: formData,
                }
            },
            invalidatesTags: (result) => {
                if (result) {
                    return [
                        { type: TYPE, id: "list" },
                        { type: TYPE, id: "list_flat" },
                        { type: TYPE, id: result?.id },
                    ];
                }
                return [];
            },
        }),
        getCountSort: build.query({
            query: () => ({ url: `/countSort` }),
            providesTags: [TYPE],
        }),
        deletePicture: build.mutation<Menu, Pick<Menu, "id"> & PostMenu>({
            query: ({ id, ...data }) => {
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    formData.append(key, data[key]);
                });
                return {
                    url: `/deletePicture/${id}`,
                    headers: {
                        "Content-type": "multipart/form-data",
                    },
                    method: "post",
                    data: formData,
                }
            },
            invalidatesTags: (result) => {
                if (result) {
                    return [
                        { type: TYPE, id: "list" },
                        { type: TYPE, id: "list_flat" },
                        { type: TYPE, id: result?.id },
                    ];
                }
                return [];
            },
        }),

        removeMenu: build.mutation<ResponseDelete, number>({
            query: (menuId: number) => ({
                url: `/menus/${menuId}`,
                method: "delete",
            }),
            invalidatesTags: [
                { type: TYPE, id: "list" },
                { type: TYPE, id: "list_flat" },
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCountSortQuery,
    useEditSortMutation,
    useGetMenuQuery,
    useGetMenusFlatQuery,
    useGetMenusQuery,
    useLazyGetMenusQuery,
    useCreateViewCountMutation,
    useCreateMenuMutation,
    useUpdateMenuMutation,
    useDeletePictureMutation,
    useRemoveMenuMutation,
} = menuApi;
