// eslint-disable-next-line
import React, { lazy, Suspense, } from "react";
import {
    Outlet,
    useLocation
} from "react-router-dom";
import { useAppSelector } from "redux/store";
import { isUserAuthenticated } from "redux/slices/user";
import {
    Box,
    Flex,
    Skeleton,
    // Text,
} from "@chakra-ui/react";
// import { HamburgerIcon } from '@chakra-ui/icons'

import { useGetMenusQuery } from "redux/services/menu";
// import { subMenuName } from "redux/slices/menu";
// import { useAppSelector } from "redux/store";

const Header = lazy(() => import("./Header/Header"));
const NavBar = lazy(() => import("components/Navbar/Navbar"));
const Footer = lazy(() => import("./Footer/Footer"));
const LandingPage = lazy(() => import("./LandingPage/LandingPage"));
// const MenuBar = lazy(() => import("./MenuBar/MenuBar"));
const Layout: React.FC = () => {
    const scrollablePaths = [
        "/setting/config/edit/1",
        "/",
        "/setting/menus",
        "/dataset",
        "/datalake",
        "/setting/datalake/create",
        "/setting/database/create",
        "/setting/menus/create",
        "/setting/roles/create",
        "/setting/permissions/create",
        "/setting/users",
        "/setting/users/create"
    ];
    const menusEditPattern = /^\/setting\/menus\/edit\/\d+$/;
    const usersEditPattern = /^\/setting\/users\/edit\/\d+$/;
    const rolesEditPattern = /^\/setting\/roles\/edit\/\d+$/;
    const permissionsEditPattern = /^\/setting\/permissions\/edit\/\d+$/;


    const { pathname } = useLocation();
    const isScrollable = scrollablePaths.includes(pathname) || menusEditPattern.test(pathname) || usersEditPattern.test(pathname) || rolesEditPattern.test(pathname) || permissionsEditPattern.test(pathname);
    const { data = null } = useGetMenusQuery();
    const filteredData = data?.filter(data => data.status === 1)
    
    const isAuthenticated = useAppSelector(isUserAuthenticated);
    return (
        <>
            {data ?
                <Flex direction="column" height="100vh"
                    overflow={isScrollable ? "scroll" : "hidden"}
                    bgColor="#EDEFF4">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Header />
                    </Suspense>
                    {pathname === "/" ?
                        <>
                            <Suspense fallback={<div>Loading...</div>}>
                                {data ?
                                    <LandingPage />
                                    :
                                    <Skeleton height={"100vh"} width="full" />
                                }
                            </Suspense>

                            <Suspense fallback={<div>Loading...</div>}>
                                <Footer />
                            </Suspense>

                        </>
                        :
                        <Suspense fallback={<div>Loading...</div>}>
                            <Box w="100%" display={"flex"} gap="10px" position={"relative"}>
                                {isAuthenticated &&
                                    <Box position="absolute" zIndex={100} height={pathname === "/setting/config/edit/1" ? "auto" : "100%"}>
                                        <NavBar data={filteredData} />
                                    </Box>
                                }
                                <Box width={"100%"} pl={{ base: "0px", md: "50px" }}>
                                    <Outlet />
                                </Box>
                            </Box>
                        </Suspense>
                    }
                </Flex >
                :
                <Skeleton height={"100vh"} width="full" />
            }
        </>
    );
};

export default Layout;
