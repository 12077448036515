import { createStandaloneToast } from "@chakra-ui/react";
import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";

import theme from "definitions/chakra/theme";
const toast = createStandaloneToast({ theme });

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.meta.arg.endpointName !== "getMe") {
        if(action.payload.data.message === "not_found" || action.payload.data.message.includes('SQLSTATE[42S02]')) {
          console.log(action.payload.data.message, 'action.payload.data.message')
        } else {
          toast({
            description: action.payload.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }

    return next(action);
  };
