import React from "react";
import { Navigate } from "react-router-dom";

import { useGetMeQuery } from "redux/services/apiService";
import { isUserAuthenticated } from "redux/slices/user";
import { useAppSelector } from "redux/store";

type Props = {};
const PrivateLayout: React.FC<Props> = ({ children }): JSX.Element => {
  const isAuthenticated = useAppSelector(isUserAuthenticated);
  const { isLoading } = useGetMeQuery();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated && !isLoading) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default PrivateLayout;
