import React from "react";
import { Navigate } from "react-router-dom";

import { useGetMeQuery } from "redux/services/apiService";
import {
    selectUserRole
} from "redux/slices/user";
import { useAppSelector } from "redux/store";

type Props = {};
const PrivateSettingRole: React.FC<Props> = ({ children }): JSX.Element => {
    const selectRole = useAppSelector(selectUserRole);

    const { isLoading } = useGetMeQuery();
    if (isLoading) {
        return null;
    }

    if (selectRole !== "super-admin") {
        return <Navigate to="/" replace />;
    }
    return <>{children}</>;
};

export default PrivateSettingRole;
