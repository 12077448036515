const colors = {
  header: {
    100: "#20232a",
  },
  main: {
    100: "#282c34",
  },
  red: {
    50: "#FAECEA",
    100: "#F2C9C5",
    200: "#EAA69F",
    300: "#E1837A",
    400: "#D96154",
    500: "#D03E2F",
    600: "#A73125",
    700: "#7D251C",
    800: "#531913",
    900: "#2A0C09",
  },
};

export default colors;
