import AxiosInstance from "axios";
import Cookies from "js-cookie";

import { BASE_URL } from "utils/constants";

const axios = AxiosInstance.create({
  baseURL: BASE_URL + "/api/v1",
  withCredentials: true,
  headers: { Accept: "application/json" },
});
// Request interceptor. Runs before your request reaches the server
const onRequest = (config) => {
  // If http method is `post | put | delete` and XSRF-TOKEN cookie is
  // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
  // proceed with the initial response
  if (
    (config.method === "post" ||
      config.method === "put" ||
      config.method === "delete") &&
    !Cookies.get("XSRF-TOKEN")
  ) {
    return setCSRFToken().then(() => config);
  }
  return config;
};

// A function that calls '/api/csrf-cookie' to set the CSRF cookies. The
// default is 'sanctum/csrf-cookie' but you can configure it to be anything.
const setCSRFToken = () => {
  return axios.get("/sanctum/csrf-cookie", {
    baseURL: BASE_URL,
  });
};

// attach your interceptor
axios.interceptors.request.use(onRequest, null);

export default axios;
